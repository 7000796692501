import { gapi } from 'gapi-script';
import { CLIENT_ID } from '../../apiKey';

// Initialize gapi if necessary and use the provided OAuth token
const gapiInit = (gapiToken) => {
	return new Promise((resolve, reject) => {
		if (gapi.client) {
			// Set the OAuth token globally for gapi requests
			gapi.auth.setToken({ access_token: gapiToken });
			resolve(gapi);
		} else {
			gapi.load('client', () => {
				gapi.client
					.init({
						scope: 'https://www.googleapis.com/auth/directory.readonly',
						clientId: CLIENT_ID
					})
					.then(() => {
						// console.log('GAPI initialized');
						// Set the access token after initialization
						gapi.auth.setToken({ access_token: gapiToken });
						resolve(gapi);
					})
					.catch((error) => {
						console.error('Error initializing GAPI', error);
						reject(error);
					});
			});
		}
	});
};

const GetDirectory = async (gapiToken, leadershipPositions) => {
	try {
		// Ensure gapi is initialized with the provided OAuth token
		await gapiInit(gapiToken);

		let pageToken = '';
		const newArray = [];

		do {
			const response = await gapi.client.request({
				method: 'GET',
				path:
					`https://people.googleapis.com/v1/people:listDirectoryPeople?mergeSources=DIRECTORY_MERGE_SOURCE_TYPE_CONTACT&pageSize=100` +
					(pageToken ? `&pageToken=${pageToken}` : '') +
					`&readMask=names,emailAddresses,organizations,phoneNumbers,photos&sources=DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE`,
				headers: {
					Authorization: `Bearer ${gapiToken}`, // Explicitly pass the token in request header
				},
			});

			if (response && response.result && response.result.people) {
				pageToken = response.result.nextPageToken || '';

				response.result.people.forEach((person) => {
					if (person.emailAddresses) {
						newArray.push({
							email: person.emailAddresses[0]['value'],
							name: person.names[0].displayName,
							givenName: person.names[0].givenName,
							familyName: person.names[0].familyName,
							memberType: ((person.organizations ?? [])[0] ?? [])['type'],
							mobile: phoneNumber(person, 'mobile'),
							photo: person.photos ? person.photos[0].url : '',
							officePosition:
								((person.organizations ?? [])[0] ?? [])['type'] === 'Office'
									? goThroughLeadershipPositions(person.emailAddresses[0]['value'], leadershipPositions)
									: '',
						});
					}
				});
			} else {
				pageToken = ''; // No more pages
			}
		} while (pageToken);

		// Sort the results
		const sortArray = newArray.sort((a, b) =>
			a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
		);

		return sortArray;
	} catch (err) {
		console.error(err);
		return err;
	}
};

export default GetDirectory;

function phoneNumber(user, phoneType) {
	if (user && user.phoneNumbers) {
		for (let i = 0; i < user.phoneNumbers.length; i++) {
			if (user.phoneNumbers[i].type === phoneType) {
				return user.phoneNumbers[i].value;
			}
		}
	}
	return '';
}

const goThroughLeadershipPositions = (email, leadershipPositions) => {
	for (let i = 0; i < leadershipPositions.length; i++) {
		if (leadershipPositions[i].currentPerson.email === email) {
			return leadershipPositions[i].positionTitle;
		}
	}
	return '';
};
