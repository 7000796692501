import React, { useContext, useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';

import { SCOPES } from '../../scopes';
import { CLIENT_ID } from '../../apiKey';
import AuthContext from './authContext';
import Tooltip from '../ui/Tooltip';

export default function RefreshToken() {
	const authCtx = useContext(AuthContext);
	const [tokenClient, setTokenClient] = useState(null);

	useEffect(() => {
		if (!tokenClient && window.google && window.google.accounts) {
			const google = window.google;

			// Initialize the token client
			const client = google.accounts.oauth2.initTokenClient({
				client_id: CLIENT_ID,
				scope: Array.isArray(SCOPES) ? SCOPES.join(' ') : SCOPES,
				callback: (tokenResponse) => {
					if (tokenResponse.error) {
						console.error('Error obtaining access token:', tokenResponse.error);
					} else {
						// console.log(tokenResponse);
						const expirationTime = new Date().getTime() + tokenResponse.expires_in * 1000;
						console.log('expirationTime', new Date(expirationTime));
						authCtx.updateGapiToken(tokenResponse.access_token);
						authCtx.updateGapiTokenExpiration(expirationTime);
					}
				},
			});

			setTokenClient(client);
		}
	}, [tokenClient, authCtx]);

	const requestToken = () => {
		// Request access token when user clicks the button
		if (tokenClient) {
			tokenClient.requestAccessToken();
		} else {
			console.error('Token client not initialized');
		}
	};

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Tooltip text='Click to re-authorize Google to access this feature.'>
				<Button variant='contained' onClick={requestToken} color='warning'>
					Re-authorize Google API Access
				</Button>
			</Tooltip>
		</Box>
	);
}
