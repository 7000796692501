import React, { useEffect, useState, useContext, Fragment } from 'react';
import { collection, getDocs } from 'firebase/firestore';

import dayjs from 'dayjs';

import {
	Box,
	DialogContent,
	Dialog,
	DialogTitle,
	DialogActions,
	TextField,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Alert,
	Switch,
	FormControlLabel,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AlertContext from '../ui/AlertContext';
import CustomClaimsContext from '../auth/CustomClaimsContext';
import AvatarSuspended from '../ui/AvatarSuspended';
import UpdateDirectoryInfo from './UpdateDirectoryInfo';
import DeleteUserAccount from './DeleteUserAccount';
import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';
import UserImage from '../ui/UserImage';
import AvatarDeleted from '../ui/AvatarDeleted';
import GetOrgUnits from './GetOrgUnits';
import getDirectoryValue from '../localFunctions/getDirectoryValue';
import getDirectoryValueFromArray from '../localFunctions/getDirectoryValueFromArray';
import formatPhoneNumber from '../localFunctions/formatPhoneNumber';
import { db } from '../../App';
import AuthContext from '../auth/authContext';

export default function DirectoryForm(props) {
	const alertCtx = useContext(AlertContext);
	const authCtx = useContext(AuthContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [isLoading, setIsLoading] = useState(null);
	const [originalData, setOriginalData] = useState();
	const [data, setData] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [formData, setFormData] = useState(null);
	const [deletedUserInfo, setDeletedUserInfo] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [orgUnits, setOrgUnits] = useState([]);
	const [orgUnitSelection, setOrgUnitSelection] = useState('');
	const [positions, setPositions] = useState(null);

	//sets member info
	useEffect(() => {
		if (props?.memberInfo?.adminResponse && props?.selection !== 'Deleted') {
			// setIsLoading(true);
			// console.log(props.memberInfo.adminResponse);
			setData(props.memberInfo.adminResponse);
			setThumbnail(props.memberInfo.peopleResponse);
			setPositions(props.memberInfo.positions);
			setOriginalData(JSON.parse(JSON.stringify(props.memberInfo.adminResponse)));
		} else if (props?.memberInfo?.adminResponse && props?.selection === 'Deleted') {
			setDeletedUserInfo(props.memberInfo.adminResponse);
		}
	}, [props.memberInfo]);

	//stops loading after initial data load and gets DirectorySettings
	useEffect(() => {
		if (data) {
			async function getData() {
				const tempData = [];
				const querySnapshot = await getDocs(collection(db, 'DirectorySettings'));
				querySnapshot.forEach((doc) => {
					tempData.push({ ...doc.data(), ID: doc.id });
				});
				const sortedData = [...tempData].sort((a, b) => a.Order - b.Order);
				setFormData(sortedData);
				setIsLoading(false);
			}
			getData();
		}
	}, [data]);

	//handles form change
	const handleFormChange = (e, item, subIndex) => {
		let value = e;

		if (item?.FieldType === 'PHONE') {
			value = formatPhoneNumber(value);
		}

		//added because was getting ResizeObserver loop error from updating too fast
		setTimeout(() => {
			setData((prevData) => {
				const newData = { ...prevData };
				//if array
				if (item.Array) {
					if (item.CustomSchema.trim() > 0) {
						if (subIndex === null) {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema])
								newData.customSchemas = { [item.CustomSchema]: {} };
							if (!newData.customSchemas[item.CustomSchema][item.Value])
								newData.customSchemas[item.CustomSchema][item.Value] = [];
							newData.customSchemas[item.CustomSchema][item.Value].push({ type: item.Type, [item.SubValue]: value });
						} else {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema])
								newData.customSchemas = { [item.CustomSchema]: {} };
							if (!newData.customSchemas[item.CustomSchema][item.Value])
								newData.customSchemas[item.CustomSchema][item.Value] = [];
							newData.customSchemas[item.CustomSchema][item.Value][subIndex][item.SubValue] = value;
						}
					}
					//if not CustomSchema
					else {
						if (subIndex === null) {
							if (!newData[item.Value]) {
								newData[item.Value] = [];
								newData[item.Value].push({ type: item.Type, [item.SubValue]: value });
							} else newData[item.Value].push({ type: item.Type, [item.SubValue]: value });
						} else {
							if (!newData[item.Value]) {
								newData[item.Value] = [];
								newData[item.Value].push({ type: item.Type, [item.SubValue]: value });
							} else {
								newData[item.Value][subIndex][item.SubValue] = value;
							}
						}
					}
				}
				//if not an array
				else {
					if (item.CustomSchema.trim().length > 0) {
						if (item.SubValue) {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema])
								newData.customSchemas = { [item.CustomSchema]: {} };
							if (!newData.customSchemas[item.CustomSchema][item.Value])
								newData.customSchemas[item.CustomSchema][item.Value] = {};
							newData.customSchemas[item.CustomSchema][item.Value][item.SubValue] = value;
						} else {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema]) {
								newData.customSchemas = { [item.CustomSchema]: {} };
							}
							newData.customSchemas[item.CustomSchema][item.Value] = value;
						}
					}
					//if not CustomSchema
					else {
						if (item.SubValue) {
							if (!newData[item.Value]) newData[item.Value] = {};
							newData[item.Value][item.SubValue] = value;
						} else {
							newData[item.Value] = value;
						}
					}
				}
				// console.log(newData);
				return newData;
			});
		}, 100);
	};

	//handles form submission
	const handleSubmit = async () => {
		setIsLoading(true);
		const response = await UpdateDirectoryInfo(claimsCtx.claims.email, claimsCtx.claims.name, data, originalData);
		updatedInfo(response);
	};

	//handles suspend button
	const handleSuspend = async () => {
		setIsLoading(true);
		const newFormState = { ...data, suspended: true };
		const response = await UpdateDirectoryInfo(claimsCtx.claims.email, claimsCtx.claims.name, newFormState, originalData);

		setIsLoading(false);
		if (response === 'no_new_data') {
			alertCtx.setMessage(`The user was not suspended. <strong>Try again</strong>.`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		} else {
			alertCtx.setMessage(`The user was suspended successfully.`);
			alertCtx.setSeverity('success');
			alertCtx.setTimer(10000);
			alertCtx.setTitle('Success');
			alertCtx.setActive(true);
		}

		if (response) {
			setTimeout(() => {
				props.handleUpdate();
			}, 3000);
		} else if (!response) {
			return;
		}
	};

	//handles unsuspend button
	const handleUnsuspend = async () => {
		setIsLoading(true);
		const newFormState = { ...data, suspended: false };
		const response = await UpdateDirectoryInfo(claimsCtx.claims.email, claimsCtx.claims.name, newFormState, originalData);

		setIsLoading(false);
		if (response === 'no_new_data') {
			alertCtx.setMessage(`The user was not unsuspended. <strong>Try again</strong>.`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		} else {
			alertCtx.setMessage(`The user was unsuspended successfully.`);
			alertCtx.setSeverity('success');
			alertCtx.setTimer(10000);
			alertCtx.setTitle('Success');
			alertCtx.setActive(true);
		}

		if (response) {
			setTimeout(() => {
				props.handleUpdate();
			}, 3000);
		} else if (!response) {
			return;
		}
	};

	//handles delete button
	const handleDelete = async () => {
		setIsLoading(true);
		const response = await DeleteUserAccount(claimsCtx.claims.email, claimsCtx.claims.name, data);

		setIsLoading(false);
		if (response) {
			alertCtx.setMessage(`The user was deleted successfully.`);
			alertCtx.setSeverity('success');
			alertCtx.setTimer(10000);
			alertCtx.setTitle('Success');
			alertCtx.setActive(true);
		} else {
			alertCtx.setMessage(`The user was not deleted. <strong>Error message: ${response}</strong>`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}

		if (response) {
			setTimeout(() => {
				props.handleClearSelectedMember();
			}, 3000);
		} else if (!response) {
			return;
		}
	};

	//handles undeleting a user
	const handleUndelete = async () => {
		if (orgUnitSelection === '') {
			return;
		} else {
			props.onUndelete(deletedUserInfo, orgUnitSelection);
			handleClose();
		}
	};

	//opens modal
	const handleOpen = async () => {
		setModalOpen(true);
		const res = await GetOrgUnits(authCtx.gapiToken);
		const units = res.result.organizationUnits.sort((a, b) =>
			a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
		);
		setOrgUnits(units);
	};

	//closes modal
	const handleClose = () => {
		setModalOpen(false);
		setOrgUnitSelection('');
	};

	//handles orgUnit selection
	const handleOrgUnitSelection = (e) => {
		setOrgUnitSelection(e);
	};

	//resets updatedSuccess
	const updatedInfo = (response) => {
		setIsLoading(false);
		if (response === 'no_new_data') {
			alertCtx.setMessage(`No new information was provided. Please try again.`);
			alertCtx.setSeverity('info');
			alertCtx.setTimer(10000);
			alertCtx.setTitle('Info');
			alertCtx.setActive(true);
		} else {
			if (response === true) {
				alertCtx.setMessage(`The user's information was updated successfully.`);
				alertCtx.setSeverity('success');
				alertCtx.setTimer(10000);
				alertCtx.setTitle('Success');
				alertCtx.setActive(true);
			} else if (response === false) {
				alertCtx.setMessage(`The information was not updated. <strong>Try again.</strong>`);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Error');
				alertCtx.setActive(true);
			}
		}
		setTimeout(() => {
			props.handleUpdate();
		}, 3000);
	};

	//handles isLoading props
	useEffect(() => {
		setIsLoading(props.isLoading);
	}, [props.isLoading]);

	//if loading return loader...
	if (isLoading) {
		return (
			<Grid xs={12}>
				<LoadingSpinner />
			</Grid>
		);
	}

	//modal for picking orgUnit to undelete a user into
	else if (modalOpen) {
		return (
			<Dialog open={modalOpen} onClose={handleClose}>
				<Box sx={{ padding: '5px' }}>
					<DialogTitle>Select an Organizational Unit to put the Deleted User back into.</DialogTitle>
					<DialogContent>
						<Box mt={1}>
							<FormControl fullWidth>
								<InputLabel id='Organizational_Unit_Selection'>Organizational Unit Selection</InputLabel>
								<Select
									labelId='Organizational_Unit_Selection'
									id='Organizational_Unit_Selection'
									value={orgUnitSelection}
									label='Organizational Unit Selection'
									onChange={(e, newValue) => {
										// console.log(e);
										// console.log(newValue);
										handleOrgUnitSelection(newValue.props.value);
									}}
								>
									{orgUnits.map((unit, key) => {
										// console.log(unit);
										return (
											<MenuItem key={key} value={unit}>
												{unit.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button variant='contained' onClick={handleClose} color='cancel'>
							Cancel
						</Button>
						<Button variant='contained' onClick={handleUndelete}>
							Select Org Unit
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
		);
	}

	//if no member selected, prompt for selection
	else if (!props.member || !data || props.noDeletedUsers) {
		return (
			<Fragment>
				<Typography variant='h4' color='primary' mb={2} textAlign={'center'}>
					Info
				</Typography>
				<Typography variant='h5' color='primary' mb={2} textAlign={'center'}>
					Select a Member from to view their information.
				</Typography>
				{props.noDeletedUsers && (
					<DialogContent>
						<Alert severity='info' sx={{ margin: '1rem' }}>
							<strong>There are no deleted users to load.</strong>
						</Alert>
					</DialogContent>
				)}
			</Fragment>
		);
	}

	//shows limited form if a deleted member is displayed
	else if (props.selection === 'Deleted' && deletedUserInfo) {
		return (
			<Fragment>
				<Box display='flex' justifyContent='right'>
					<Tooltip text="Closes the current person's info.">
						<DisabledByDefaultRoundedIcon color='action' onClick={props.onClose} />
					</Tooltip>
				</Box>

				{/* user name & avatar */}
				<Stack alignItems='center'>
					<Typography variant='h5' color='primary' mb={2} textAlign={'center'}>
						{deletedUserInfo.givenName} {deletedUserInfo.familyName}
					</Typography>
					<AvatarDeleted />
				</Stack>

				{/* buttons at top */}
				{claimsCtx?.claims?.admin && (
					<Stack direction='row' spacing={2} justifyContent='center' mb={2} mt={3}>
						<Button variant='contained' startIcon={<DeleteIcon />} color='primary' onClick={handleOpen}>
							Un-Delete
						</Button>
					</Stack>
				)}

				{/* form */}
				<form id='form' onSubmit={handleSubmit}>
					{/* preferred name */}
					<TextField
						disabled
						margin='dense'
						id='givenName'
						label='Preferred Name'
						fullWidth
						variant='outlined'
						color='secondary'
						value={deletedUserInfo.givenName}
						onChange={handleFormChange}
					/>

					{/* last name */}
					<TextField
						disabled
						margin='dense'
						id='lastName'
						label='Last Name'
						fullWidth
						variant='outlined'
						color='secondary'
						value={deletedUserInfo.familyName}
						onChange={handleFormChange}
					/>

					{/* ept email */}
					<TextField
						disabled
						margin='dense'
						id='eptEmail'
						label='EPT Email'
						fullWidth
						variant='outlined'
						color='secondary'
						defaultValue={deletedUserInfo.email}
					/>
				</form>
			</Fragment>
		);
	}

	//member form
	else if (data) {
		return (
			<Fragment>
				<Box display='flex' justifyContent='right'>
					<Tooltip text="Closes the current person's info.">
						<DisabledByDefaultRoundedIcon color='primary' onClick={props.onClose} />
					</Tooltip>
				</Box>
				{/* user name & avatar */}
				<Stack alignItems='center'>
					<Typography variant='h4' color='primary' mb={2} textAlign={'center'}>
						{data.name.givenName} {data.name.familyName}
					</Typography>
					{data.suspended ? (
						<AvatarSuspended />
					) : (
						<UserImage photoURL={thumbnail} name={data.name.givenName + ' ' + data.name.familyName} />
					)}
				</Stack>

				{/* buttons at top */}
				{claimsCtx?.claims?.admin && (
					<Stack direction='row' spacing={2} justifyContent='center' mb={2} mt={3}>
						<Button variant='contained' startIcon={<SaveRoundedIcon />} color='saveButton' onClick={handleSubmit}>
							Save
						</Button>

						{data.suspended ? (
							<Button
								variant='contained'
								startIcon={<RemoveCircleOutlineRoundedIcon />}
								color='otherButton'
								onClick={handleUnsuspend}
							>
								Unsuspend
							</Button>
						) : (
							<Button
								variant='contained'
								startIcon={<RemoveCircleOutlineRoundedIcon />}
								color='otherButton'
								onClick={handleSuspend}
							>
								Suspend
							</Button>
						)}

						{!data.suspended ? (
							<Tooltip text='The user must be suspended first.'>
								<div>
									<Button variant='contained' startIcon={<DeleteIcon />} color='deleteButton' disabled>
										Delete
									</Button>
								</div>
							</Tooltip>
						) : (
							<Button variant='contained' startIcon={<DeleteIcon />} color='deleteButton' onClick={handleDelete}>
								Delete
							</Button>
						)}
					</Stack>
				)}

				<br />

				{/* form */}
				{formData &&
					formData.map((item, index) => {
						if (claimsCtx?.claims?.admin || item.DisplayOnDirectory) {
							if (item.Array) {
								let itemFound = getDirectoryValueFromArray(data, item);
								if (
									item.InputType === undefined ||
									item.InputType === null ||
									item.InputType === 'STRING' ||
									item.InputType === 'PHONE'
								) {
									return (
										<TextField
											key={index}
											disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
											margin='dense'
											id={item.Label}
											label={item.Label}
											fullWidth
											variant='outlined'
											color='secondary'
											value={itemFound.value}
											onChange={(e) => {
												handleFormChange(e.target.value, item, itemFound.index);
											}}
											type={'text'}
											InputLabelProps={{
												shrink: getDirectoryValueFromArray(data, item)?.value?.length > 0,
											}}
										/>
									);
								} else if (item.InputType === 'DATE') {
									return (
										<LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
											<DatePicker
												disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
												label={item.Label}
												value={itemFound.value ? dayjs(itemFound.value) : null}
												onChange={(e) => {
													if (e === null) handleFormChange('', item);
													else {
														const newDate = `${e.$y}-${('0' + (e.$M + 1)).slice(-2)}-${('0' + e.$D).slice(-2)}`;
														handleFormChange(newDate, item, itemFound.index);
													}
												}}
												sx={{
													width: '100%',
													marginTop: '0.75rem',
													marginBottom: '0.5rem',
												}}
												components={{
													TextField: TextField, // Specifies TextField as the input component
												}}
												componentsProps={{
													textField: {
														label: item.Label,
													}, // Props for TextField
												}}
											/>
										</LocalizationProvider>
									);
								} else if (item.InputType === 'SELECTION') {
									return (
										<FormControl
											fullWidth
											margin='dense'
											key={index}
											disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
										>
											<InputLabel id='inputType'>{item.Label}</InputLabel>
											<Select
												color='secondary'
												fullWidth
												labelId={item.Label}
												id={item.Label}
												value={itemFound.value}
												label={item.Label}
												onChange={(e) => {
													handleFormChange(e.target.value, item, itemFound.index);
												}}
												margin='dense'
											>
												{item.DropDownChoices &&
													item.DropDownChoices.map((choice, choiceIndex) => {
														return (
															<MenuItem key={choiceIndex} value={choice}>
																{choice}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>
									);
								}
							} else {
								if (
									item.InputType === undefined ||
									item.InputType === null ||
									item.InputType === 'STRING' ||
									item.InputType === 'PHONE'
								) {
									return (
										<TextField
											key={index}
											disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
											margin='dense'
											id={item.Label}
											label={item.Label}
											fullWidth
											variant='outlined'
											color='secondary'
											value={getDirectoryValue(item, data)}
											onChange={(e) => {
												handleFormChange(e.target.value, item);
											}}
											type={'text'}
											InputLabelProps={{ shrink: getDirectoryValue(item, data).length > 0 }}
										/>
									);
								} else if (item.InputType === 'DATE') {
									return (
										<LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
											<DatePicker
												disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
												label={item.Label}
												value={getDirectoryValue(item, data) ? dayjs(getDirectoryValue(item, data)) : null}
												onChange={(e) => {
													if (e === null) handleFormChange('', item);
													else {
														const newDate = `${e.$y}-${('0' + (e.$M + 1)).slice(-2)}-${('0' + e.$D).slice(-2)}`;
														handleFormChange(newDate, item);
													}
												}}
												sx={{
													width: '100%',
													marginTop: '0.75rem',
													marginBottom: '0.5rem',
												}}
												components={{
													TextField: TextField,
												}}
												componentsProps={{
													textField: {
														label: item.Label,
													},
												}}
											/>
										</LocalizationProvider>
									);
								} else if (item.InputType === 'BOOL') {
									return (
										<FormControlLabel
											key={index}
											control={
												<Switch
													disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
													color='secondary'
													checked={getDirectoryValue(item, data) === '' ? false : getDirectoryValue(item, data)}
													onChange={(event) => {
														const e = getDirectoryValue(item, data) === '' ? true : !getDirectoryValue(item, data);
														handleFormChange(e, item);
													}}
												/>
											}
											label={item.Label}
											labelPlacement='start'
										/>
									);
								} else if (item.InputType === 'SELECTION') {
									return (
										<FormControl fullWidth margin='dense' key={index}>
											<InputLabel id='inputType'>{item.Label}</InputLabel>
											<Select
												color='secondary'
												fullWidth
												labelId={item.Label}
												id={item.Label}
												value={getDirectoryValue(item, data)}
												label='Input Type'
												onChange={(e) => {
													handleFormChange(e.target.value, item);
												}}
												disabled={!item.EditableOnDirectory || !claimsCtx?.claims?.admin}
												margin='dense'
											>
												{item.DropDownChoices &&
													item.DropDownChoices.map((choice, choiceIndex) => {
														return (
															<MenuItem key={choiceIndex} value={choice}>
																{choice}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>
									);
								}
							}
						}
					})}

				{/* Positions */}
				{positions.map((position, index) => {
					return (
						<TextField
							key={index}
							disabled
							margin='dense'
							id={position}
							label='Position'
							fullWidth
							variant='outlined'
							color='secondary'
							value={position}
						/>
					);
				})}

				{/* buttons at bottom */}
				{claimsCtx?.claims?.admin && (
					<Stack direction='row' spacing={2} justifyContent='center' mb={2} mt={3}>
						<Button variant='contained' startIcon={<SaveRoundedIcon />} color='saveButton' onClick={handleSubmit}>
							Save
						</Button>

						{data.suspended ? (
							<Button
								variant='contained'
								startIcon={<RemoveCircleOutlineRoundedIcon />}
								color='otherButton'
								onClick={handleUnsuspend}
							>
								Unsuspend
							</Button>
						) : (
							<Button
								variant='contained'
								startIcon={<RemoveCircleOutlineRoundedIcon />}
								color='otherButton'
								onClick={handleSuspend}
							>
								Suspend
							</Button>
						)}

						{!data.suspended ? (
							<Tooltip text='The user must be suspended first.'>
								<div>
									<Button variant='contained' startIcon={<DeleteIcon />} color='deleteButton' disabled>
										Delete
									</Button>
								</div>
							</Tooltip>
						) : (
							<Button variant='contained' startIcon={<DeleteIcon />} color='deleteButton' onClick={handleDelete}>
								Delete
							</Button>
						)}
					</Stack>
				)}
			</Fragment>
		);
	}
}
