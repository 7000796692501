import React, { useContext, useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Stack,
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import Tooltip from '../ui/Tooltip';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import classes from './CreatePositionModal.module.css';
import GetParentPositions from './GetParentPositions';
import CustomClaimsContext from '../auth/CustomClaimsContext';
import LoadingSpinner from '../ui/LoadingSpinner';

export default function CreatePositionModal(props) {
	const claimsCtx = useContext(CustomClaimsContext);
	const [loading, setLoading] = useState(true);
	const [isPositionOrGroup, setIsPositionOrGroup] = useState('');
	const [title, setTitle] = useState('');
	const [groupEmail, setGroupEmail] = useState();
	const [ccEmailAddresses, setCCEmailAddresses] = useState();
	const [chairVotesInTie, setChairVotesInTie] = useState();
	const [allowGroupVoting, setAllowGroupVoting] = useState();
	const [votingDays, setVotingDays] = useState();
	const [path, setPath] = useState('');
	const [isValid, setIsValid] = useState({
		create: false,
		title: false,
		path: false,
		form: false,
	});
	const [parentArray, setParentArray] = useState([
		{ name: '---', key: '---' },
		{ name: 'Root (top level)', key: 'org_chart' },
	]);
	const [exists, setExists] = useState(false);

	//handles closing the modal
	const handleClose = () => {
		props.onClose();
	};

	//gets the list of parent positions
	useEffect(() => {
		const getPositions = async () => {
			setLoading(true);
			const response = await GetParentPositions(props.allPositions);
			const newArray = [...response];
			const sortedChoices = newArray.sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
			);
			setParentArray((prevState) => [...prevState, ...sortedChoices]);
			setLoading(false);
		};

		getPositions();
	}, [props.allPositions]);

	//sets if a position or a group
	const handlePositionOrGroupChange = (e) => {
		setIsPositionOrGroup(e.target.value);
		setIsValid((prevState) => ({
			...prevState,
			create: true,
		}));
	};

	//sets allow group voting
	const handleAllowGroupVotingChange = (e) => {
		setAllowGroupVoting(e.target.value);
	};

	//sets chair votes in tie
	const handleChairVotesInTieChange = (e) => {
		setChairVotesInTie(e.target.value);
	};

	//sets the voting days
	const handleVotingDaysChange = (e) => {
		setVotingDays(e.target.value);
	};

	//sets the group email address
	const handleGroupEmailAddressChange = (e) => {
		setGroupEmail(e.target.value);
	};

	//sets the cc email addresses
	const handleCCEmailAddressesChange = (e) => {
		setCCEmailAddresses(e.target.value);
	};

	//sets the title
	const handleTitleChange = (e) => {
		setTitle(e.target.value);
		if (e.target.value.trim() !== '') {
			setIsValid((prevState) => ({
				...prevState,
				title: true,
			}));
		} else {
			setIsValid((prevState) => ({
				...prevState,
				title: false,
			}));
		}
	};

	//sets the path
	const handlePathChange = (e) => {
		setPath(e.target.value);
		if (e.target.value.length > 0 && e.target.value !== '---') {
			setIsValid((prevState) => ({
				...prevState,
				path: true,
			}));
		} else {
			setIsValid((prevState) => ({
				...prevState,
				path: false,
			}));
		}
	};

	//checks form validity
	useEffect(() => {
		if (isValid.create && isValid.title && isValid.path) {
			setIsValid((prevState) => ({
				...prevState,
				form: true,
			}));
		} else {
			setIsValid((prevState) => ({
				...prevState,
				form: false,
			}));
		}
	}, [isValid.create, isValid.title, isValid.path]);

	//handles form submission
	const handleOnSubmit = async (e) => {
		e.preventDefault();
		const timestamp = new Date().toISOString();

		const data = {
			type: isPositionOrGroup,
			title: title,
			path: `${path}/children/${title}`,
			allowGroupVoting: JSON.parse(allowGroupVoting),
			votingDays: votingDays,
			chairVotesInTie: JSON.parse(chairVotesInTie),
			groupEmail: groupEmail,
			ccEmailAddresses: ccEmailAddresses,
			id: timestamp,
			history: {
				[timestamp]: {
					user: claimsCtx?.claims?.email,
					note: isPositionOrGroup === 'position' ? 'Position Created' : 'Group Created',
					data: {
						allowGroupVoting: JSON.parse(allowGroupVoting),
						groupEmail: groupEmail,
						path: `${path}/children/${title}`,
						title: title,
						type: isPositionOrGroup,
					},
				},
			},
		};

		// console.log(data);

		const db = props.db;
		let childRef;

		//if top level, then no child necessary
		if (path === 'org_chart') {
			childRef = doc(db, `${path}`, title);
		} else {
			childRef = doc(db, `${path}/children`, title);
		}
		const docSnap = await getDoc(childRef);

		if (docSnap.exists()) {
			setExists(true);
		} else {
			setExists(false);

			//if top level, then no children necessary
			if (path === 'org_chart') {
				await setDoc(doc(db, `${path}`, title), data);
			} else {
				await setDoc(doc(db, `${path}/children`, title), data);
			}

			props.onReload();
			handleClose();
		}
	};

	//display if loading
	if (loading) {
		return (
			<Dialog
				open={props.open}
				onClose={handleClose}
				fullWidth
				scroll='body'
				PaperProps={{ sx: { minHeight: '30vh' } }}
			>
				<LoadingSpinner />
			</Dialog>
		);
	}

	//if not loading, display this
	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			fullWidth
			scroll='body'
			PaperProps={{ sx: { minHeight: '30vh', padding: '0.5rem' } }}
		>
			<DialogTitle>Create a New Position or Group</DialogTitle>

			{/* position or group */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Tooltip text="A position is a job held by one individual. Select 'Group' for jobs held by more than one individual." />
				<span>&nbsp;</span>
				<FormControl>
					<FormLabel id='type'>Type</FormLabel>
					<RadioGroup
						aria-labelledby='type'
						value={isPositionOrGroup}
						name='radio-buttons-group'
						row
						onChange={handlePositionOrGroupChange}
					>
						<FormControlLabel value='group' control={<Radio />} label='Group' />
						<FormControlLabel value='position' control={<Radio />} label='Position' />
					</RadioGroup>
				</FormControl>
			</Box>

			{/* title */}
			{exists && <div className={classes.failure}>The Title already exists. Choose a different Name.</div>}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Tooltip text='Title of the position.' />
				<span>&nbsp;</span>
				<TextField
					id='positionTitle'
					label='Title'
					fullWidth
					margin='normal'
					variant='outlined'
					defaultValue={title}
					onChange={handleTitleChange}
					required
				/>
			</Box>

			{/* path */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					marginTop: '1rem',
				}}
			>
				<Tooltip text='Select who this position or group reports to.' />
				<span>&nbsp;</span>
				<FormControl fullWidth>
					<InputLabel id='reports-to'>Reports To</InputLabel>
					<Select labelId='reports-to' id='reports-to' value={path} label='Reports To' onChange={handlePathChange}>
						{parentArray.map((item) => (
							<MenuItem key={item.key} value={item.key}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{/* group email address */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Enter the groups email address.' />
					<span>&nbsp;</span>
					<TextField
						id='groupEmailAddress'
						label='Group Email Address'
						fullWidth
						margin='normal'
						variant='outlined'
						defaultValue={groupEmail}
						onChange={handleGroupEmailAddressChange}
					/>
				</Box>
			)}

			{/* CC email addresses */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text="Enter any email addresses that should be CC'd on the votes. Separate multiple emails with commas." />
					<span>&nbsp;</span>
					<TextField
						id='ccEmailAddresses'
						label='CC Email Addresses'
						fullWidth
						margin='normal'
						variant='outlined'
						defaultValue={ccEmailAddresses}
						onChange={handleCCEmailAddressesChange}
					/>
				</Box>
			)}

			{/* allow group voting */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Select if voting should be allowed for this group.' />
					<span>&nbsp;</span>
					<FormControl disabled={groupEmail === '' || groupEmail === undefined || groupEmail === null}>
						<FormLabel id='type'>Voting</FormLabel>
						<RadioGroup
							aria-labelledby='type'
							value={allowGroupVoting?.toString() || ''}
							name='radio-buttons-group'
							row
							onChange={handleAllowGroupVotingChange}
						>
							<FormControlLabel value={'true'} control={<Radio />} label='Allowed' />
							<FormControlLabel value={'false'} control={<Radio />} label='Not Allowed' />
						</RadioGroup>
					</FormControl>
				</Box>
			)}

			{/* voting days */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Enter the number of days allowed for a non-urgent vote.' />
					<span>&nbsp;</span>
					<TextField
						id='votingDuration'
						label='Voting Days'
						disabled={!allowGroupVoting}
						fullWidth
						margin='normal'
						variant='outlined'
						defaultValue={votingDays || ''}
						onChange={handleVotingDaysChange}
					/>
				</Box>
			)}

			{/* chair breaks tie only */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Select if the Chair only votes if there is a tie.' />
					<span>&nbsp;</span>
					<FormControl disabled={groupEmail === '' || groupEmail === undefined || groupEmail === null}>
						<FormLabel id='type'>Chair Votes in Tie Only</FormLabel>
						<RadioGroup
							aria-labelledby='type'
							value={chairVotesInTie?.toString() || ''}
							name='radio-buttons-group'
							row
							onChange={handleChairVotesInTieChange}
						>
							<FormControlLabel value={'true'} control={<Radio />} label='True' />
							<FormControlLabel value={'false'} control={<Radio />} label='False' />
						</RadioGroup>
					</FormControl>
				</Box>
			)}

			{/* save button */}
			<Stack direction='row' spacing={2} justifyContent='center' mt='2rem' mb='2rem'>
				<Button
					variant='contained'
					startIcon={<SaveRoundedIcon />}
					color='saveButton'
					onClick={handleOnSubmit}
					disabled={!isValid.form}
				>
					SAVE
				</Button>
			</Stack>
		</Dialog>
	);
}
