import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Alert, Container, AlertTitle } from '@mui/material';

import SignIn from '../auth/SignIn';
import AuthContext from '../auth/authContext';
import AlertContext from '../ui/AlertContext';

import classes from './MainNavigation.module.css';
import EPTLogo from '../images/EPT Logo 2023 (Web) (small).png';
import EPTSmallLogo from '../images/EPT Logo 2023 (Icon Only) (small).png';
import AvatarMenu from '../ui/AvatarMenu';
import NavigationMenu from '../ui/NavigationMenu';
import { auth } from '../../App';

function MainNavigation() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const isLoggedIn = authCtx.isLoggedIn;
	const photoURL = auth?.currentUser?.photoURL || '';

	return (
		<header className={classes.header}>
			<div className={classes.containerLogo}>
				<Container sx={{ display: { xs: 'none', sm: 'block' } }}>
					<Link to='/'>
						<img className={classes.logo} src={EPTLogo} alt='EPT Logo' />
					</Link>
				</Container>
				<Container sx={{ display: { xs: 'block', sm: 'none' } }}>
					<Link to='/'>
						<img className={classes.logo} src={EPTSmallLogo} alt='Small EPT Logo' />
					</Link>
				</Container>
			</div>

			<div className={classes.containerAlert}>
				{alertCtx.active && (
					<Alert
						severity={alertCtx.severity}
						onClose={() => {
							alertCtx.setActive(false);
						}}
					>
						<AlertTitle>{alertCtx.title}</AlertTitle>
						{/* used dangerouslySetInnerHTML as all html messages are pre-set and safe and this allows for html content to be rendered */}
						<div dangerouslySetInnerHTML={{ __html: alertCtx.message }} />
					</Alert>
				)}
			</div>

			<div className={classes.containerLogin}>
				{authCtx.isLoggedIn && <NavigationMenu />}
				{!isLoggedIn && <SignIn />}
				{isLoggedIn && <AvatarMenu photoURL={photoURL} />}
			</div>
		</header>
	);
}

export default MainNavigation;
