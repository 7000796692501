import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as Yup from 'yup';
import { getIn } from 'formik';

import { Typography, TextField, Button, Box, IconButton, Container, Chip } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AlertContext from '../ui/AlertContext';
import CustomClaimsContext from '../auth/CustomClaimsContext';
import MRT_TABLE from '../ui/MRT_TABLE';

import { db } from '../../App';
import Tooltip from '../ui/Tooltip';

export default function EvaluationSettings({ onSettingsChange }) {
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [evaluationSettings, setEvaluationSettings] = useState([]);
	const [loading, setLoading] = useState(true);
	const [currentRow, setCurrentRow] = useState(null);

	// get evaluation settings data
	useEffect(() => {
		fetchEvaluationSettings();
	}, [alertCtx]);

	const fetchEvaluationSettings = async () => {
		try {
			const querySnapshot = await getDocs(collection(db, 'EvaluationSettings'));
			const settingsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
			setEvaluationSettings(settingsData);
		} catch (error) {
			console.error('Error fetching documents: ', error);
			alertCtx.setMessage(`Error fetching documents: ${error}`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}
		setLoading(false);
	};

	const handleSubmit = async (values, { resetForm }) => {
		setLoading(true);

		// Trim whitespace from the values
		const trimmedValues = {
			EvaluateePosition: values.evaluateePosition.trim(),
			VisibleTo: values.visibleTo.map((item) => ({
				name: item.name.trim(),
				email: item.email.trim(),
			})),
		};

		try {
			if (currentRow) {
				// Update existing document
				const docRef = doc(db, 'EvaluationSettings', currentRow.id);
				await updateDoc(docRef, trimmedValues);
				alertCtx.setMessage('Evaluation Setting updated successfully!');
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Updated');
				alertCtx.setTimer(5000);
			} else {
				// Add new document
				await addDoc(collection(db, 'EvaluationSettings'), trimmedValues);
				alertCtx.setMessage('Evaluation Setting saved successfully!');
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Saved');
				alertCtx.setTimer(5000);
			}

			onSettingsChange();

			resetForm();
			setCurrentRow(null);
			fetchEvaluationSettings();
		} catch (error) {
			console.error('Error saving document: ', error);
			alertCtx.setMessage(`Error saving document: ${error}`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
		}

		setLoading(false);
		alertCtx.setActive(true);
	};

	const handleEdit = (row) => {
		setCurrentRow(row.original);
	};

	const columns = useMemo(
		() => [
			{
				header: 'ID',
				accessorFn: (row) => row?.id,
				id: 'id',
			},
			{
				header: 'Evaluatee Position',
				accessorFn: (row) => row?.EvaluateePosition,
				id: 'evaluatee_position',
			},
			{
				header: 'Visible To',
				accessorFn: (row) =>
					row?.VisibleTo?.map((item, index) => (
						<Tooltip text={item.email} key={index}>
							<Chip label={item.name} variant='outlined' color='primary' sx={{ mr: 1 }} />
						</Tooltip>
					)),
				id: 'visible_to',
			},
			{
				header: 'Actions',
				accessorKey: 'actions',
				id: 'actions',
				Cell: ({ row }) => (
					<Button
						variant='outlined'
						onClick={() => handleEdit(row)}
						color='primary'
						disabled={!claimsCtx?.claims?.admin}
					>
						Edit
					</Button>
				),
			},
		],
		[]
	);

	return (
		<>
			<Typography variant='h5' color={'primary'} textAlign={'center'} mb={3}>
				Evaluation Settings
			</Typography>

			<Formik
				initialValues={{
					evaluateePosition: currentRow ? currentRow.EvaluateePosition : '',
					visibleTo: currentRow ? currentRow.VisibleTo : [{ name: '', email: '' }],
				}}
				validationSchema={Yup.object().shape({
					evaluateePosition: Yup.string().required('Evaluatee Position is required'),
					visibleTo: Yup.array()
						.of(
							Yup.object().shape({
								name: Yup.string().required('Name is required'),
								email: Yup.string().email('Invalid email').required('Email is required'),
							})
						)
						.min(1, 'At least one Name/Email is required'),
				})}
				enableReinitialize
				onSubmit={handleSubmit}
			>
				{({ values, handleChange, handleBlur, touched, errors }) => (
					<Box width={'500px'} maxWidth={'90%'} margin={'0 auto'} mb={3}>
						<Form>
							<Box mb={3}>
								<TextField
									fullWidth
									label='Evaluatee Position'
									name='evaluateePosition'
									value={values.evaluateePosition}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.evaluateePosition && Boolean(errors.evaluateePosition)}
									helperText={touched.evaluateePosition && errors.evaluateePosition}
									variant='outlined'
									margin='normal'
									disabled={!claimsCtx?.claims?.admin}
								/>
								<FieldArray name='visibleTo'>
									{({ push, remove }) => (
										<Box>
											{values.visibleTo.map((_, index) => (
												<Box key={index} display='flex' alignItems='center' mb={2}>
													<TextField
														label='Name'
														name={`visibleTo.${index}.name`}
														value={values.visibleTo[index].name}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															getIn(touched, `visibleTo.${index}.name`) &&
															Boolean(getIn(errors, `visibleTo.${index}.name`))
														}
														helperText={
															getIn(touched, `visibleTo.${index}.name`) && getIn(errors, `visibleTo.${index}.name`)
														}
														variant='outlined'
														margin='normal'
														sx={{ mr: 2 }}
														disabled={!claimsCtx?.claims?.admin}
													/>
													<TextField
														label='Email'
														name={`visibleTo.${index}.email`}
														value={values.visibleTo[index].email}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															getIn(touched, `visibleTo.${index}.email`) &&
															Boolean(getIn(errors, `visibleTo.${index}.email`))
														}
														helperText={
															getIn(touched, `visibleTo.${index}.email`) && getIn(errors, `visibleTo.${index}.email`)
														}
														variant='outlined'
														margin='normal'
														sx={{ mr: 2 }}
														disabled={!claimsCtx?.claims?.admin}
													/>
													<IconButton
														onClick={() => remove(index)}
														disabled={values.visibleTo.length === 1 || !claimsCtx?.claims?.admin}
													>
														<DeleteOutlineIcon color='primary' />
													</IconButton>
												</Box>
											))}
											<Container sx={{ display: 'flex', justifyContent: 'center' }}>
												<Button
													variant='outlined'
													color='primary'
													startIcon={<AddIcon />}
													onClick={() => push({ name: '', email: '' })}
													disabled={!claimsCtx?.claims?.admin}
												>
													Add Visible To
												</Button>
											</Container>
										</Box>
									)}
								</FieldArray>
							</Box>

							<Container sx={{ display: 'flex', justifyContent: 'center' }}>
								<Button variant='contained' color='primary' type='submit' disabled={!claimsCtx?.claims?.admin}>
									Save Position
								</Button>
							</Container>
						</Form>
					</Box>
				)}
			</Formik>

			<Box mt={5}>
				<MRT_TABLE
					data={evaluationSettings}
					columns={columns}
					sortBy={[{ Id: 'evaluatee_position', Direction: 'asc', TrueFalse: true }]}
					loading={loading}
					hiddenColumns={['id']}
				/>
			</Box>
		</>
	);
}
