import React, { useState, useEffect, useContext, useRef } from 'react';
import html2canvas from 'html2canvas';

import { doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import {
	Autocomplete,
	Chip,
	Container,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Box,
	Stack,
	Typography,
	TextField,
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import sendGrid from '../sendGrid/sendGrid';
import Tooltip from '../ui/Tooltip';
import TextEditor from '../textEditor/TextEditor';
import CustomClaimsContext from '../auth/CustomClaimsContext';
import AlertContext from '../ui/AlertContext';
import AccordionUI from '../ui/AccordionUI';
import Chart_Table from './Chart_Table';
import AreObjectsEqual from '../localFunctions/AreObjectsEqual';
import StepperUI from '../ui/StepperUI';
import { db } from '../../App';
import LoadingSpinner from '../ui/LoadingSpinner';
import getFirestoreDocument from '../localFunctions/getFirestoreDocument';

export default function Dashboard_SendEmail({ dashboard }) {
	const auth = getAuth();
	const claimsCtx = useContext(CustomClaimsContext);
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [groups, setGroups] = useState(null);
	const [emailGroups, setEmailGroups] = useState(null);
	const [emailSettings, setEmailSettings] = useState(JSON.parse(JSON.stringify(dashboard.emailSettings)));
	const [originalEmailSettings, setOiriginalEmailSettings] = useState(
		JSON.parse(JSON.stringify(dashboard.emailSettings))
	);
	const [equal, setEqual] = useState(null);
	const [saving, setSaving] = useState(null);
	const [activeStep, setActiveStep] = useState(null);
	const tableRef = useRef(null);
	const [selectedChart, setSelectedChart] = useState(null);
	const [selectedDashboard, setSelectedDashboard] = useState(dashboard);
	const [isRendered, setIsRendered] = useState(null);
	const inputs = [
		{ label: 'From Group', value: 'fromGroup', disabled: false, tooltip: 'Select a group to send an email from.' },
		{
			label: 'To',
			value: 'to',
			disabled: false,
			tooltip:
				'Not recommended to use.  Any emails listed here will be included on any Reply All and Reply To email responses.',
		},
		{
			label: 'CC',
			value: 'cc',
			disabled: false,
			tooltip:
				'Not recommended to use.  Any emails listed here will be included on any Reply All email responses, but not on Reply To.',
		},
		{
			label: 'BCC',
			value: 'bcc',
			disabled: false,
			tooltip:
				'Recommended method to send emails to groups & individuals.  Any emails listed here will not be included in any Reply All or Reply To fields.',
		},
		{ label: 'Email Subject', value: 'emailSubject', disabled: false, tooltip: null },
		{
			label: 'Email Title',
			value: 'emailTitle',
			disabled: false,
			tooltip: 'Recommended - A header for the email.  If no input then the Email Subject will be used.',
		},
	];

	// useEffect(() => {
	// 	console.log('sendEmailAs', sendEmailAs);
	// 	// console.log('emailGroups', emailGroups);
	// }, [emailSettings, groups, emailGroups, sendEmailAs]);

	//get groups
	useEffect(() => {
		if (!groups && auth.currentUser) {
			async function getData() {
				const uid = auth.currentUser.uid;
				const res = await getFirestoreDocument(`Users/${uid}`);
				// console.log(res);

				if (res.status === 'success') {
					const unsortedAllGroups = res.data.SendEmailAs_AllGroups || [];
					const sortedAllGroups = unsortedAllGroups.sort((a, b) => a.name.localeCompare(b.name));
					setGroups(sortedAllGroups);

					const unsortedSendEmailTo = res.data.SendEmailTo || [];
					const sortedSendEmailTo = unsortedSendEmailTo.sort((a, b) => a.name.localeCompare(b.name));
					setEmailGroups(sortedSendEmailTo);
				} else {
					alertCtx.setMessage(res.message);
					alertCtx.setSeverity(res.status);
					alertCtx.setTitle('Error');
					alertCtx.setActive(true);

					setGroups([]);
					setEmailGroups([]);
				}
			}
			getData();
		}
	}, [groups, auth.currentUser]);

	//turns off loading once data set
	useEffect(() => {
		if (groups && emailSettings) {
			setIsLoading(false);
			// console.log('groups', groups);
		}
	}, [groups, emailSettings]);

	const handleActiveStepChange = (step) => {
		setActiveStep(step);
	};

	/**
	 * resets isRendered when not on that step
	 */
	useEffect(() => {
		if (activeStep !== 2) {
			setIsRendered(null);
		}
	}, [activeStep]);

	//sets the selectedChart
	useEffect(() => {
		let found = false;
		dashboard?.dashboard?.charts?.map((chart, chartIndex) => {
			if (chart.chartUniqueID === emailSettings.chartUniqueID) {
				setSelectedChart(chart);
				found = true;
			}
		});
		if (!found) setSelectedChart(null);
	}, [emailSettings.chartUniqueID]);

	/**
	 * checks if emailSettings and originalEmailSettings are equal
	 * if not equal, then allow save button to be active
	 */
	useEffect(() => {
		setEqual(AreObjectsEqual(emailSettings, originalEmailSettings));
	}, [emailSettings, originalEmailSettings]);

	//updates the emailSettings fields
	const updateEmailSettingsField = (newItem, field) => {
		setEmailSettings((prevData) => {
			const newData = { ...prevData };
			newData[field] = newItem;
			return newData;
		});
	};

	//updates the emailBody of emailSettings
	const handleEmailBodyChange = (obj) => {
		updateEmailSettingsField(obj.text, 'emailHTML');
		updateEmailSettingsField(obj.valid, 'textEditorValid');
	};

	//saves changes to dashboard.emailSettings
	const saveEmailChanges = async (dashboardID) => {
		setSaving(true);
		const dashboardRef = doc(db, 'dashboards', dashboardID);
		await updateDoc(dashboardRef, { emailSettings: emailSettings });
		setOiriginalEmailSettings(JSON.parse(JSON.stringify(emailSettings)));
		setSaving(false);
	};

	const handleSendEmail = async () => {
		// setSaving(true);

		const dataURL = await captureImage();

		let template_id = 'd-e737b47592874685adf84df535d2ea01'; //default, without inline image
		if (dataURL) template_id = 'd-37d1bc9a961a4b52af6ad13af65d2fb7'; // with inline image

		let toField = [];
		let ccField = [];
		let bccField = [];
		if (emailSettings.to) toField = emailSettings.to.map((group) => group.email);
		if (emailSettings.cc) ccField = emailSettings.cc.map((group) => group.email);
		if (emailSettings.bcc) bccField = emailSettings.bcc.map((group) => group.email);

		const response = await sendGrid({
			to: toField,
			...(ccField ? { cc: ccField } : {}),
			...(bccField ? { bcc: bccField } : {}),
			replyTo: emailSettings.fromGroup.email,
			plainText: emailSettings.plainText || emailSettings.emailHTML,
			html: emailSettings.emailHTML,
			template_id: template_id,
			emailSubject: emailSettings.emailSubject,
			emailTitle: emailSettings.emailTitle || emailSettings.emailSubject,
			emailHTML: emailSettings.emailHTML,
			fromPerson: claimsCtx?.claims.name,
			fromGroup: emailSettings.fromGroup.name,
			...(emailSettings.attachments ? { attachments: emailSettings.attachments } : {}),
			...(dataURL
				? {
						attachments: [
							{
								content: dataURL.replace('data:image/png;base64,', ''),
								filename: 'image.png',
								type: 'image/png',
								disposition: 'inline',
								content_id: 'myimagecid',
							},
						],
				  }
				: {}),
		});
		// console.log(response);

		alertCtx.setSeverity(response.data.code === 200 ? 'success' : 'error');
		alertCtx.setMessage(response.data.message);
		alertCtx.setTimer(response.data.code === 200 ? 10000 : null);
		alertCtx.setActive(true);

		setSaving(false);
	};

	/**
	 * Captures and returns a data URL
	 * @returns dataURL of the image
	 */
	const captureImage = async () => {
		if (tableRef.current) {
			const canvas = await html2canvas(tableRef.current);
			const tableImage = canvas.toDataURL('image/png');
			if (tableImage === 'data:,') return null;
			else return tableImage;
		}
	};

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			{emailSettings && (
				<AccordionUI title='Email Options' subtitle='Open to adjust email settings or send email' parent={true}>
					<StepperUI
						steps={['Email Settings', 'Select Chart', 'Send Email']}
						handleActiveStepChange={handleActiveStepChange}
						equal={equal}
						saveFunction={saveEmailChanges}
						saveLocation={dashboard.id}
						saving={saving}
					>
						{activeStep === 0 && (
							<Stack spacing={1} mb={1}>
								{inputs.map((input, key) => {
									let componentToRender;
									switch (input.label) {
										case 'To':
										case 'CC':
										case 'BCC':
											componentToRender = (
												<Autocomplete
													disabled={saving || input.disabled || !groups}
													size='small'
													fullWidth
													multiple
													id={input.label}
													options={emailGroups}
													getOptionLabel={(option) => `${option.name} -- ${option.email}`}
													value={emailSettings[input.value] || []}
													freeSolo
													onChange={(e, newValue) => {
														if (typeof newValue[newValue.length - 1] === 'string') {
															const newestItem = newValue.pop();
															newValue.push({ email: newestItem, name: newestItem });
														}
														updateEmailSettingsField(newValue, input.value);
													}}
													renderTags={(value, getTagProps) =>
														value.map((option, index) => {
															// Assign key directly to the Chip component
															const { key, ...tagProps } = getTagProps({ index });
															return (
																<Chip
																	variant='contained'
																	color='otherButton'
																	label={option.name}
																	key={option.email} // Correct placement of key
																	{...tagProps} // Spread the remaining props without the key
																/>
															);
														})
													}
													renderInput={(params) => (
														<TextField
															{...params}
															variant='outlined'
															label={input.label}
															placeholder='Choose an email address or enter one not listed (click enter when done)'
														/>
													)}
												/>
											);
											break;
										case 'From Group':
											componentToRender = (
												<Autocomplete
													disabled={saving || input.disabled || !groups}
													size='small'
													fullWidth
													id={input.label}
													options={groups}
													getOptionLabel={(option) => `${option.name} -- ${option.email}`}
													value={emailSettings[input.value] || null}
													isOptionEqualToValue={(option, value) => option.email === value.email}
													onChange={(e, newValue) => {
														updateEmailSettingsField(newValue, input.value);
													}}
													renderTags={(value, getTagProps) =>
														value.map((option, index) => (
															<Chip
																variant='contained'
																color='otherButton'
																label={option.name}
																key={option.email} // Pass key directly
																{...getTagProps({ index })}
															/>
														))
													}
													renderInput={(params) => (
														<TextField
															{...params}
															variant='outlined'
															label={input.label}
															placeholder='Choose a group to send an email from.'
														/>
													)}
												/>
											);
											break;
										default:
											componentToRender = (
												<TextField
													disabled={saving || input.disabled || !groups}
													label={input.label}
													variant='outlined'
													size='small'
													margin='dense'
													value={emailSettings[input.value] || ''}
													onChange={(e) => updateEmailSettingsField(e.target.value, input.value)}
													color='secondary'
													sx={{ width: '95%' }}
												/>
											);
											break;
									}
									return (
										<Stack
											direction='row'
											sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
											key={key}
										>
											<Container sx={{ width: '5%', marginLeft: '1rem', marginRight: '1rem' }}>
												{input.tooltip && <Tooltip text={input.tooltip} />}
											</Container>
											{componentToRender}
										</Stack>
									);
								})}
								<Box sx={{ display: 'flex', justifyContent: 'left' }}>
									<Container sx={{ width: '5%', marginLeft: '1rem', marginRight: '1rem' }} />
									<Stack sx={{ width: '95%' }}>
										<Typography variant='caption'>Email Body</Typography>
										<TextEditor
											disabled={saving || !groups}
											initialContent={emailSettings.emailHTML}
											onChange={handleEmailBodyChange}
											// valid={emailSettings.textEditorValid}
										/>
									</Stack>
								</Box>
							</Stack>
						)}

						{activeStep === 1 && (
							<Box display='flex' justifyContent='center' width='100%'>
								<Stack spacing={2} width='50%'>
									<Stack direction='row' spacing={1} alignItems='center'>
										<FormControl variant='outlined' fullWidth>
											<InputLabel>Choose a Chart</InputLabel>
											<Select
												fullWidth
												label='Choose a Chart'
												value={selectedChart || ''}
												onChange={(e) => {
													let chartUniqueID;
													if (e.target.value) chartUniqueID = e.target.value.chartUniqueID;
													else chartUniqueID = null;
													updateEmailSettingsField(chartUniqueID, 'chartUniqueID');
												}}
											>
												<MenuItem value={null}>None</MenuItem>
												{dashboard.dashboard.charts.map((chart, index) => (
													<MenuItem key={index} value={chart}>
														{chart.title}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<Tooltip text={'Select a chart to be embedded in the email.'} />
									</Stack>

									{selectedChart?.type === 'Table' && (
										<Chart_Table chart={selectedChart} dashboard={selectedDashboard} />
									)}
								</Stack>
							</Box>
						)}

						{activeStep === 2 && (
							<Stack spacing={1}>
								<Box display='flex' justifyContent='center'>
									<Button
										disabled={saving || !equal || (selectedChart && !isRendered)}
										variant='outlined'
										color='otherButton'
										startIcon={<EmailOutlinedIcon />}
										onClick={handleSendEmail}
									>
										SEND EMAIL
									</Button>
								</Box>

								<div ref={tableRef}>
									{selectedChart?.type === 'Table' && (
										<Chart_Table
											chart={selectedChart}
											dashboard={selectedDashboard}
											onRendered={(loading) => {
												setIsRendered(!loading);
											}}
										/>
									)}
								</div>
							</Stack>
						)}
					</StepperUI>
				</AccordionUI>
			)}
		</>
	);
}
