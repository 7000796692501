import React, { useState, useRef, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onSnapshot, doc } from 'firebase/firestore';

import { auth, db } from '../../App';

const AuthContext = React.createContext({
	firebaseToken: null,
	firebaseTokenExpiration: null,
	gapiToken: null,
	gapiTokenExpiration: null,
	isLoggedIn: false,
	isLoading: true, // Add isLoading to context
	login: (expirationTime) => {},
	logout: () => {},
	updateGapiToken: (gapiToken) => {},
	updateGapiTokenExpiration: (gapiTokenExpiration) => {},
	dataRetrieved: false,
});

export const AuthContextProvider = (props) => {
	const [dataRetrieved, setDataRetrieved] = useState(() => {
		const data = localStorage.getItem('dataRetrieved');
		return data === 'true';
	});
	const [firebaseToken, setFirebaseToken] = useState(localStorage.getItem('firebaseToken') || null);
	const [firebaseTokenExpiration, setFirebaseTokenExpiration] = useState(
		localStorage.getItem('firebaseTokenExpiration') ? Number(localStorage.getItem('firebaseTokenExpiration')) : null
	);
	const [gapiToken, setGapiToken] = useState(localStorage.getItem('gapiToken') || null);
	const [gapiTokenExpiration, setGapiTokenExpiration] = useState(
		localStorage.getItem('gapiTokenExpiration') ? Number(localStorage.getItem('gapiTokenExpiration')) : null
	);
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true); // New state to track loading
	const userIsLoggedIn = !!firebaseToken;
	const firebaseRefreshTimer = useRef(null);
	const gapiRefreshTimer = useRef(null);
	const refreshTimes = useRef(0);
	const previousToken = useRef(null);

	// Create a ref to store the unsubscribe function for Firestore
	const unsubscribeFirestoreRef = useRef(null);

	if (localStorage.getItem('refreshTimes') !== null) {
		refreshTimes.current = Number(localStorage.getItem('refreshTimes'));
	}

	// UseEffect for auth.onAuthStateChanged
	useEffect(() => {
		const unsubscribeAuth = auth.onAuthStateChanged((user) => {
			setUser(user);
			setIsLoading(false);
		});

		return () => {
			unsubscribeAuth();
		};
	}, []);

	// UseEffect for Firestore listener
	useEffect(() => {
		if (user && auth.currentUser?.uid) {
			// console.log('uid', auth.currentUser.uid);

			unsubscribeFirestoreRef.current = onSnapshot(
				doc(db, `Users`, auth.currentUser.uid),
				async (docSnapshot) => {
					// console.log('doc', docSnapshot.data());
					const firebaseTokenResult = await user.getIdTokenResult();
					// console.log('firebaseToken', firebaseTokenResult);

					setFirebaseToken(firebaseTokenResult.token);
					localStorage.setItem('firebaseToken', firebaseTokenResult.token);

					const expirationTime = new Date(firebaseTokenResult.expirationTime).getTime();
					setFirebaseTokenExpiration(expirationTime);
					localStorage.setItem('firebaseTokenExpiration', expirationTime);

					if (firebaseTokenResult.token !== previousToken.current) {
						previousToken.current = firebaseTokenResult.token;
						console.log('FirebaseToken obtained and updated if prior has/will expire.');
					}

					setDataRetrieved(true);
					localStorage.setItem('dataRetrieved', true);
					console.log('Data retrieved and state updated.');
				},
				(error) => {
					console.error('Firestore listener error:', error);
				}
			);
		}

		return () => {
			if (unsubscribeFirestoreRef.current) {
				console.log('Unsubscribing from Firestore listener...');
				unsubscribeFirestoreRef.current();
				unsubscribeFirestoreRef.current = null;
			}
		};
	}, [user]);

	//stores gapi token
	const updateGapiToken = (gapiToken) => {
		setGapiToken(gapiToken);
		localStorage.setItem('gapiToken', gapiToken);
		console.log('Gapi token updated in local storage.');
	};

	// updates the gapiTokenExpiration
	const updateGapiTokenExpiration = (gapiTokenExpiration) => {
		const adjustedExpirationTime = Number(gapiTokenExpiration) - 1000 * 60; // Subtract 60 seconds from the remainingTime
		const remainingTime = adjustedExpirationTime - new Date().getTime();

		setGapiTokenExpiration(Number(gapiTokenExpiration));
		localStorage.setItem('gapiTokenExpiration', gapiTokenExpiration);
		console.log('Gapi token expiration updated in local storage.');

		clearTimeout(gapiRefreshTimer.current);
		gapiRefreshTimer.current = setTimeout(() => {
			console.log('Gapi token expired.');
			updateGapiToken(null);
		}, remainingTime);
	};

	// Login handler
	const loginHandler = (expirationTime) => {
		console.log('Logging in user...');

		const initialLoginTime = localStorage.getItem('initialLoginTime')
			? Number(localStorage.getItem('initialLoginTime'))
			: new Date().getTime();
		localStorage.setItem('initialLoginTime', initialLoginTime);
		console.log('initialLoginTime', new Date(initialLoginTime));

		if (!expirationTime) {
			console.error('Invalid expiration time received:', expirationTime);
			return;
		}

		const adjustedExpirationTime = Number(expirationTime) - 1000 * 60; // Subtract 60 seconds from the remainingTime
		const remainingTime = adjustedExpirationTime - new Date().getTime();

		if (!firebaseTokenExpiration) {
			setFirebaseTokenExpiration(expirationTime);
			localStorage.setItem('firebaseTokenExpiration', expirationTime);
		}

		if (!gapiTokenExpiration) {
			console.log('Setting gapiTokenExpiration');
			updateGapiTokenExpiration(expirationTime);
			localStorage.setItem('gapiTokenExpiration', expirationTime);
		}

		if (isNaN(remainingTime) || remainingTime <= 0) {
			console.error('Invalid remaining time:', remainingTime);
			return;
		}

		// resets the firebaseRefreshTimer
		clearTimeout(firebaseRefreshTimer.current);
		firebaseRefreshTimer.current = setTimeout(() => {
			console.log('Executing refreshToken...');
			refreshToken();
		}, remainingTime);
		localStorage.setItem('logoutTime', new Date().getTime() + remainingTime);

		console.log('User logged in. Token expiration set. Refresh timer set for:', remainingTime / 1000 / 60, 'minutes');
	};

	// Logout handler
	const logoutHandler = () => {
		if (unsubscribeFirestoreRef.current) {
			unsubscribeFirestoreRef.current();
			unsubscribeFirestoreRef.current = null;
		}

		signOut(auth)
			.then(() => {
				setGapiToken(null);
				setFirebaseToken(null);
				setFirebaseTokenExpiration(null);
				setGapiTokenExpiration(null);
				clearTimeout(firebaseRefreshTimer.current);
				clearTimeout(gapiRefreshTimer.current);
				localStorage.clear();
				refreshTimes.current = '';
				console.log('User logged out successfully at:', new Date());
			})
			.catch((error) => {
				console.error('Error during logout:', error);
				setGapiToken(null);
				setFirebaseToken(null);
				setFirebaseTokenExpiration(null);
				setGapiTokenExpiration(null);
				clearTimeout(firebaseRefreshTimer.current);
				clearTimeout(gapiRefreshTimer.current);
				localStorage.clear();
				refreshTimes.current = '';
				logoutHandler();
			});
	};

	// attemptes to refresh the firebaseToken
	const refreshToken = async () => {
		console.log('Attempting to refresh firebaseToken...');

		const initialLoginTime = Number(localStorage.getItem('initialLoginTime'));
		if (initialLoginTime) {
			const elapsedTime = new Date().getTime() - Number(initialLoginTime);
			if (elapsedTime >= 12 * 59 * 60 * 1000) {
				// 12 hours in milliseconds
				console.log('12 hours elapsed since initial login. Logging out.');
				logoutHandler();
				return;
			}
		}

		console.log('Current RefreshTimes:', refreshTimes.current);
		if (refreshTimes.current < 12) {
			try {
				const user = auth.currentUser;
				if (user) {
					const tokenResult = await user.getIdTokenResult(true);
					const expirationTime = new Date(tokenResult.expirationTime).getTime();
					const token = tokenResult.token;

					//set firebaseTokenExpiration
					setFirebaseTokenExpiration(expirationTime);
					localStorage.setItem('firebaseTokenExpiration', expirationTime);
					console.log('expirationTime', new Date(expirationTime));

					//set firebaseToken
					setFirebaseToken(token);
					localStorage.setItem('firebaseToken', token);

					console.log('firebaseToken refreshed successfully');

					const functions = getFunctions();
					const verifyEPTEmailWithCustomClaimsV2 = httpsCallable(functions, 'verifyEPTEmailWithCustomClaimsV2');
					verifyEPTEmailWithCustomClaimsV2({}).then((result) => {
						const verified = result.data.verified;

						if (verified) {
							console.log('User verified successfully. Updating login state.');
							loginHandler(expirationTime);
							refreshTimes.current = refreshTimes.current + 1;
							localStorage.setItem('refreshTimes', refreshTimes.current);
							console.log('Refresh times incremented and stored:', refreshTimes.current);
						} else {
							console.log('User verification failed. Logging out.');
							logoutHandler();
						}
					});
				}
			} catch (error) {
				console.error('Error during firebaseToken refresh:', error);
				logoutHandler();
			}
		}
		// sign user out
		else {
			logoutHandler();
		}
	};

	// sets up the refresh timer(s) on component mount
	useEffect(() => {
		//firebaseRefreshTimer
		if (firebaseToken && firebaseTokenExpiration) {
			const adjustedExpirationTime = firebaseTokenExpiration - 1000 * 60; // Subtract 60 seconds from the remainingTime
			const remainingTime = adjustedExpirationTime - new Date().getTime();

			// resets firebaseRefreshTimer if remainingTime
			if (remainingTime > 0) {
				clearTimeout(firebaseRefreshTimer.current);
				console.log('Setting firebaseRefreshTimer on mount for:', remainingTime / 1000 / 60, 'minutes');
				firebaseRefreshTimer.current = setTimeout(() => {
					console.log('Executing refreshToken...');
					refreshToken();
				}, remainingTime);
			} else {
				console.log('Firebase token expired, logging user out.');
				logoutHandler();
			}
		}

		// console.log('firebaseTokenExpiration', new Date(firebaseTokenExpiration));
		// console.log('firebaseToken', firebaseToken);

		//gapiRefreshTimer
		if (gapiToken && gapiTokenExpiration) {
			const adjustedExpirationTime = gapiTokenExpiration - 1000 * 60; // Subtract 60 seconds from the remainingTime
			const remainingTime = adjustedExpirationTime - new Date().getTime();

			// resets gapiRefreshTimer if remainingTime
			if (remainingTime > 0) {
				clearTimeout(gapiRefreshTimer.current);
				console.log('Setting gapiRefreshTimer on mount for:', remainingTime / 1000 / 60, 'minutes');
				gapiRefreshTimer.current = setTimeout(() => {
					console.log('Gapi token expired.');
					updateGapiToken(null);
				}, remainingTime);
			} else {
				console.log('Gapi token expired.');
				updateGapiToken(null);
			}
		}
	}, []);

	// // logs user out if logout gets stuck
	// useEffect(() => {
	// 	if (!userIsLoggedIn) {
	// 		const logoutTimeout = setTimeout(() => {
	// 			console.log('No firebaseToken and firebaseTokenExpiration. Logging user out.');
	// 			logoutHandler();
	// 		}, 5000);
	// 		setTimer(logoutTimeout);
	// 	} else if (userIsLoggedIn) {
	// 		clearTimeout(timer);
	// 	}
	// }, [userIsLoggedIn]);

	const contextValue = {
		firebaseToken: firebaseToken,
		firebaseTokenExpiration: firebaseTokenExpiration,
		gapiToken: gapiToken,
		gapiTokenExpiration: gapiTokenExpiration,
		isLoggedIn: userIsLoggedIn,
		isLoading, // Provide isLoading in context
		login: loginHandler,
		logout: logoutHandler,
		updateGapiToken: updateGapiToken,
		updateGapiTokenExpiration: updateGapiTokenExpiration,
		dataRetrieved: dataRetrieved,
	};

	return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
