import React, { useState, useMemo, useEffect } from 'react';

import { MaterialReactTable, useMRT_RowVirtualizer } from 'material-react-table';

/**
 * MRT_TABLE Component
 *
 * Displays a Material React Table with customizable data and columns.
 *
 * @param {Array} data - The data to display in the table.
 * @param {Array} columns - The column definitions for the table.
 * @param {boolean} loading - Indicates if the table is in a loading state.
 * @param {Array} sortBy - An array of columns to sort by initially. Must contain Id, Direction, and TrueFalse (i.e. sortBy={ [{Id: 'id', Direction: 'asc', TrueFalse: true}] }).
 * @param {number} pageSize - OPTIONAL - Number of rows per page. Default is 25.
 * @param {Array} hiddenColumns - OPTIONAL - Column IDs to hide in the table.
 * @param {string} maxHeight - OPTIONAL - Maximum height of the table container. Default is '60vh'.
 * @returns {JSX.Element} The rendered MaterialReactTable component.
 */
export default function MRT_TABLE({
	data,
	columns,
	loading,
	pageSize = 25,
	sortBy,
	hiddenColumns = [],
	maxHeight = '60vh',
}) {
	const [globalFilter, setGlobalFilter] = useState('');

	const columnVisibility = useMemo(() => {
		// Start with the default column(s) you always want to hide
		const visibility = {};

		// Add the hiddenColumns to the visibility object
		hiddenColumns.forEach((colId) => {
			visibility[colId] = false;
		});

		return visibility;
	}, [hiddenColumns]);

	return (
		<MaterialReactTable
			columns={columns}
			data={data}
			initialState={{
				globalFilter,
				density: 'compact',
				pagination: { pageIndex: 0, pageSize: pageSize },
				sorting: sortBy.map((item) => ({ id: item.Id, [item.Direction]: item.TrueFalse })), // Map over sortBy array
				columnVisibility, // Apply the dynamic column visibility
			}}
			state={{
				globalFilter,
				isLoading: loading,
			}}
			enableStickyHeader
			onGlobalFilterChange={setGlobalFilter}
			enableGlobalFilter={true} // Explicitly enable the global filter
			muiTableContainerProps={{
				components: {
					Scroller: useMRT_RowVirtualizer, // Enable virtualization
				},
				sx: {
					maxHeight: maxHeight,
					minHeight: '100px',
				},
			}}
			muiTableHeadCellProps={{
				sx: {
					backgroundColor: 'white',
				},
			}}
			muiTablePaperProps={{
				elevation: 0, //change the mui box shadow
				sx: {
					//stripe the rows, make even rows a darker color
					'& tr:nth-of-type(even)': {
						backgroundColor: '#f5f5f5',
					},
					minWidth: '600px',
				},
			}}
			displayColumnDefOptions={{
				'mrt-row-actions': {
					muiTableHeadCellProps: {
						align: 'center',
					},
					size: 120,
				},
			}}
		/>
	);
}
