import { collection, getDocs, query, where, orderBy, or, and } from 'firebase/firestore';
import { db } from '../../App';

import GetUserDataDirectory from '../auth/GetUserDataDirectory';

/**
 * finds what the parameter date value is when the parameter type is date
 * @param {object} param
 * @returns a date
 */
const findParamDateValue = (param) => {
	if (param.rollingTimeframe) {
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Set time to midnight
		const dailyMilliseconds = 1000 * 60 * 60 * 24;
		switch (param.rollingUnit) {
			case 'Days':
				return new Date(today - dailyMilliseconds * param.rollingValue);
			case 'Weeks':
				return new Date(today - dailyMilliseconds * 7 * param.rollingValue);
			case 'Months':
				return new Date(today.getMonth() - param.rollingValue);
			case 'Years':
				return new Date(today.getFullYear() - param.rollingValue);
		}
	}

	// console.log('formated date:', new Date(formatDate(new Date(param.value))));
	return new Date(formatDate(new Date(param.value)));
};

function formatDate(date) {
	const yyyy = date.getFullYear().toString().padStart(4, '0');
	const MM = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
	const dd = date.getDate().toString().padStart(2, '0');
	const hh = date.getHours().toString().padStart(2, '0');
	const mm = date.getMinutes().toString().padStart(2, '0');
	const ss = date.getSeconds().toString().padStart(2, '0');
	const ms = date.getMilliseconds().toString().padStart(3, '0');

	const timezoneOffset = -date.getTimezoneOffset();
	const sign = timezoneOffset >= 0 ? '+' : '-';
	const offsetHours = Math.abs(Math.floor(timezoneOffset / 60))
		.toString()
		.padStart(2, '0');
	const offsetMinutes = Math.abs(timezoneOffset % 60)
		.toString()
		.padStart(2, '0');

	return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}.${ms}${sign}${offsetHours}:${offsetMinutes}`;
}

export default async function Dashboard_Query(dashboard, chart) {
	return new Promise(async (resolve) => {
		try {
			console.log('dashboard: ', dashboard);
			// console.log('chart: ', chart);

			const dashboardRef = collection(db, 'dashboards', dashboard?.id, `${dashboard?.id}-${chart?.data}`);
			const queryParams = chart.queryParameters;
			const orderByParams = [{ field: 'Report Date', value: 'desc' }];
			let q = query(dashboardRef);

			// console.log('queryParams', queryParams);

			/**
			 * if phi data, set phiKey
			 */
			let phiKey = null;
			if (chart.data === 'Data-PHI') {
				phiKey = await GetUserDataDirectory('INCOMPLETE_CHARTS');
				console.log('phiKey', phiKey);
				q = query(q, where('All Staff', '==', phiKey));
			}

			/**
			 * sets the query parameters
			 */
			if (queryParams?.length > 0)
				for (const param of queryParams) {
					if (param.type === 'orGroup') {
						// Construct OR query
						const orConditions = param.conditions.map((subParam) =>
							where(
								subParam.parameter.name,
								subParam.operation,
								subParam?.parameter?.type === 'Date'
									? findParamDateValue(subParam)
									: subParam?.parameter?.type === 'Boolean'
									? subParam.value === 'true'
										? true
										: false
									: subParam.value
							)
						);
						q = query(q, or(...orConditions));
					} else {
						// Handle as a simple parameter
						q = query(
							q,
							where(
								param.parameter.name,
								param.operation,
								param?.parameter?.type === 'Date'
									? findParamDateValue(param)
									: param?.parameter?.type === 'Boolean'
									? param.value === 'true'
										? true
										: false
									: param.value
							)
						);
					}
				}
			/**
			 * sets the orderBy parameters
			 */
			if (orderByParams?.length > 0)
				for (const orderByParam of orderByParams) q = query(q, orderBy(orderByParam.field, orderByParam.value));

			/**
			 * gets the query
			 */
			const snapshot = await getDocs(q);

			/**
			 * if the query is empty
			 */
			if (snapshot.empty) resolve({ error: null, data: null, message: 'No matching documents.' });

			/**
			 * parses through all items and saves the document id and the document as one item
			 * also converts timestamp items to dates
			 */
			const tempItems = [];
			snapshot.forEach((doc) => {
				const document = { ...doc.data() };

				/**
				 * converts any keys that are timestamps into date objects
				 */
				// console.log('doc', doc.data());
				for (const key of Object.keys(document)) {
					// console.log('key', key);
					if (document[key])
						for (const subKey of Object.keys(document[key])) {
							if (subKey === 'nanoseconds') {
								document[key] = new Date(document[key].toDate());
							}
						}
				}

				tempItems.push({
					id: doc.id,
					...document,
				});
			});

			resolve({ error: null, data: tempItems, message: 'Data obtained.' });
		} catch (error) {
			console.log(error);
			// Check if the error message contains the URL pattern from Firestore
			const urlMatch = error.message.match(/https:\/\/console\.firebase\.google\.com\/[^ ]+/);
			if (urlMatch) {
				// console.log('Firestore index required. Create it using this link:', urlMatch[0]);
				resolve({
					error: 'Firebase Composite Index',
					message: `Have a Firebase Admin use the following link to enable this search:`,
					data: null,
					url: urlMatch[0],
				});
			} else {
				// console.log('An error occurred:', error.message);
				resolve({ error: 'Error', message: `An error occurred: ${error.message}`, data: null });
			}
		}
	});
}
