import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { collection, addDoc, doc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';

import { Alert, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { Edit, AddRounded } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

import moment from 'moment/moment';

import CreateLOASabbatical from '../components/loaSabbatical/CreateLOASabbatical';
import EditLOASabbatical from '../components/loaSabbatical/EditLOASabbatical';
import PageCards from '../components/ui/PageCards';
import { MaterialReactTable } from 'material-react-table';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import Tooltip from '../components/ui/Tooltip';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import GetAllPeopleList from '../components/ui/GetAllPeopleList';
import { db } from '../App';
import AuthContext from '../components/auth/authContext';

export default function LOAandSabbatical() {
	const claimsCtx = useContext(CustomClaimsContext);
	const [displayCreateModal, setDisplayCreateModal] = useState(null);
	const [displayEditModal, setDisplayEditModal] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [allPeople, setAllPeople] = useState([]);
	const [rowData, setRowData] = useState();
	const [alert, setAlert] = useState({ active: false, type: null, message: null });
	const authCtx = useContext(AuthContext);

	//table columns
	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				accessorKey: 'name',
				header: 'Name',
			},
			{
				accessorKey: 'email',
				header: 'Email',
				id: 'email',
			},
			{
				accessorKey: 'LOAorSabbatical',
				header: 'LOA or Sabbatical',
			},
			{
				header: 'Total Days',
				Cell: ({ row }) =>
					moment(row.original.endDate.getTime()).diff(moment(row.original.startDate.getTime()), 'days') + 1, //added 1 to the date calculation to be inclusive of endDate
			},
			{
				accessorKey: 'startDate',
				header: 'Start Date',
				Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
			},
			{
				accessorKey: 'endDate',
				header: 'End Date',
				Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
			},
		],
		[]
	);

	//initial load - gets the leadership positions & sets tableData, loads allPeople
	useEffect(() => {
		if (db) {
			const getData = async () => {
				setIsLoading(true);
				setTableData([]);

				const tableData = [];
				const querySnapshot = await getDocs(collection(db, 'LOAandSabbatical'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					data.startDate = data.startDate.toDate();
					data.endDate = data.endDate.toDate();
					tableData.push({ id: document.id, ...data });
				});

				setTableData(() => tableData);
				const allPeople = await GetAllPeopleList(authCtx.gapiToken);
				setAllPeople(allPeople);
				setIsLoading(false);
			};

			getData();
		}
	}, [authCtx.gapiToken]);

	//button for adding a leadership position
	const handleCreateLOAorSabbatical = () => {
		setDisplayCreateModal(true);
	};

	//cancels the modals
	const cancelModal = () => {
		setDisplayCreateModal(null);
		setDisplayEditModal(null);
	};

	//handles the page reloading when a change is made
	const handleReload = async () => {
		setIsLoading(true);
		setTableData([]);

		const tableData = [];
		const querySnapshot = await getDocs(collection(db, 'LOAandSabbatical'));
		querySnapshot.forEach((document) => {
			let data = document.data();
			data.startDate = data.startDate.toDate();
			data.endDate = data.endDate.toDate();
			tableData.push({ id: document.id, ...data });
		});

		setTableData(() => tableData);
		setIsLoading(false);
	};

	//submits the createModal
	const handleOnSubmitCreate = async (e) => {
		setIsLoading(true);
		cancelModal();

		try {
			await addDoc(collection(db, 'LOAandSabbatical'), e);
			setAlert({
				active: true,
				type: 'success',
				message: `${e.name}'s ${e.LOAorSabbatical} has been created.`,
			});
			setTimeout(() => {
				handleReload();
			}, 1000);
		} catch (error) {
			setAlert({
				active: true,
				type: 'error',
				message: `${e.name}'s ${e.LOAorSabbatical} could not be created.  Please refresh the page and try again. Error message for IT: ${error}`,
			});
			setTimeout(() => {
				handleReload();
			}, 1000);
		}
	};

	//handles the edit button
	const handleEditButton = (row) => {
		setRowData(row);
		setDisplayEditModal(true);
	};

	//handles the edit submit button
	const handleOnEditButton = async (e) => {
		cancelModal();
		setIsLoading(true);
		const id = e.id;
		delete e.id; //removes id, this is already passed to each object when the list is pulled
		const data = { ...e };

		const documentRef = doc(db, 'LOAandSabbatical', id);

		try {
			await updateDoc(documentRef, data);
			setAlert({
				active: true,
				type: 'success',
				message: `${e.name}'s ${e.LOAorSabbatical} has been updated.`,
			});
			setTimeout(() => {
				handleReload();
			}, 1000);
		} catch (error) {
			setAlert({
				active: true,
				type: 'error',
				message: `${e.name}'s ${e.LOAorSabbatical} could not be updated.  Please refresh the page and try again. Error message for IT: ${error}`,
			});
			setTimeout(() => {
				handleReload();
			}, 1000);
		}
	};

	//handles the delete button
	const handleDeleteButton = async (row) => {
		const documentRef = doc(db, 'LOAandSabbatical', row.id);
		try {
			await deleteDoc(documentRef);
			setTimeout(() => {
				setAlert({ active: true, type: 'success', message: `${row.name}'s ${row.LOAorSabbatical} has been deleted.` });
				handleReload();
			}, 1000);
		} catch (error) {
			setTimeout(() => {
				setAlert({
					active: true,
					type: 'error',
					message: `There was an error with ${row.name}'s ${row.LOAorSabbatical} being deleted. Please refresh the page and try again. Error message for IT: ${error}`,
				});
				handleReload();
			}, 1000);
		}
	};

	//resets the alert 5 seconds later
	useEffect(() => {
		if (alert.active) {
			setTimeout(() => {
				setAlert({ active: false, type: null, message: null });
			}, 5000);
		}
	}, [alert]);

	//display if loading
	if (isLoading) {
		return (
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					LOAs and Sabbaticals
				</Typography>

				{/* displays alerts */}
				{alert.active && <Alert severity={alert.type}>{alert.message}</Alert>}

				<br />

				<LoadingSpinner />
			</PageCards>
		);
	}

	return (
		<Fragment>
			{/* create new position modal */}
			{displayCreateModal && (
				<CreateLOASabbatical
					submit={handleOnSubmitCreate}
					cancel={cancelModal}
					open={displayCreateModal}
					tableData={tableData}
					rowData={rowData}
					allPeople={allPeople}
					db={db}
				/>
			)}

			{/* edit position modal */}
			{displayEditModal && (
				<EditLOASabbatical
					submit={handleOnEditButton}
					cancel={cancelModal}
					open={displayEditModal}
					tableData={tableData}
					rowData={rowData}
					allPeople={allPeople}
				/>
			)}

			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					LOAs and Sabbaticals
				</Typography>

				{/* displays alerts */}
				{alert.active && <Alert severity={alert.type}>{alert.message}</Alert>}

				{/* ACTIVE TABLE - Admin & Not Mobile*/}
				<Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
					{claimsCtx?.claims?.admin && (
						<MaterialReactTable
							muiTablePaperProps={{
								elevation: 0, //change the mui box shadow
								sx: {
									'& tr:nth-of-type(even)': {
										backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
									},
								},
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
								},
							}}
							columns={columns}
							data={tableData}
							enableGrouping
							editingMode='modal' //default
							enableEditing
							initialState={{
								density: 'compact',
								expanded: true, //expand all groups by default
								pagination: { pageIndex: 0, pageSize: 25 },
								sorting: [{ id: 'startDate', desc: true }], //sort by group by default
								columnVisibility: {
									id: false,
									email: false,
								},
							}}
							/* sets the action column settings */
							renderRowActions={({ row, table }) => (
								<Box sx={{ display: 'flex', gap: '1rem' }}>
									<Tooltip text='Edit this row.'>
										<IconButton onClick={() => handleEditButton(row.original)}>
											<Edit />
										</IconButton>
									</Tooltip>
									<Tooltip text='Delete this row.'>
										<IconButton onClick={() => handleDeleteButton(row.original)}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</Box>
							)}
							/* sets the top toolbar actions */
							renderTopToolbarCustomActions={() => (
								<Button
									color='primary'
									onClick={handleCreateLOAorSabbatical}
									variant='contained'
									startIcon={<AddRounded />}
								>
									NEW LOA OR Sabbatical
								</Button>
							)}
						/>
					)}
				</Grid>

				{/* ACTIVE TABLE - Admin & Mobile*/}
				<Grid sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
					{claimsCtx?.claims?.admin && (
						<MaterialReactTable
							muiTablePaperProps={{
								elevation: 0, //change the mui box shadow
								sx: {
									'& tr:nth-of-type(even)': {
										backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
									},
								},
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
								},
							}}
							columns={columns}
							data={tableData}
							enableGrouping
							initialState={{
								density: 'compact',
								expanded: true, //expand all groups by default
								pagination: { pageIndex: 0, pageSize: 25 },
								sorting: [{ id: 'startDate', desc: true }], //sort by group by default
								columnVisibility: {
									id: false,
									email: false,
								},
							}}
						/>
					)}
				</Grid>
			</PageCards>
			<br />
		</Fragment>
	);
}
