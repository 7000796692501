import { gapi } from 'gapi-script';

/**
 * Fetches the photo URL of a user by email address using the Google Admin SDK.
 *
 * @param {string} emailAddress - The email address of the user.
 * @param {string} token - The access token for authentication.
 * @returns {Promise<string>} - A promise that resolves to the user's photo URL.
 */
export default async function GetPhotoURL(emailAddress, token) {
	if (!emailAddress) {
		return '';
	}

	try {
		// Load the GAPI client if not already loaded
		if (!gapi.client || !gapi.client.request) {
			await new Promise((resolve, reject) => {
				gapi.load('client', {
					callback: resolve,
					onerror: () => reject(new Error('Failed to load GAPI client')),
					timeout: 5000,
					ontimeout: () => reject(new Error('Timed out loading GAPI client')),
				});
			});
		}

		// Set the access token
		gapi.client.setToken({ access_token: token });

		const response = await gapi.client.request({
			method: 'GET',
			path: `https://admin.googleapis.com/admin/directory/v1/users/${encodeURIComponent(emailAddress)}`,
			params: {
				projection: 'basic',
				viewType: 'domain_public',
			},
		});

		const photoUrl = response.result.thumbnailPhotoUrl || '';

		return photoUrl;
	} catch (error) {
		console.error('Error in GetPhotoURL:', error?.result?.error?.message || error.message);
		throw new Error(error?.result?.error?.message || 'Unknown error occurred.');
	}
}
